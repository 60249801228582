import { Injectable } from '@angular/core';
import { LocalStoreService } from './local-store.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Utils } from '../utils';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Only for demo purpose
  authenticated = true;
  apiurl = 'http://kiltas.com.tr/api/';



  constructor(private store: LocalStoreService, private router: Router, private http: HttpClient) {
    this.checkAuth();
  }

  checkAuth() {
    this.authenticated = this.store.getItem('token');
  }

  getuser() {
    return of({});
  }

  signin(credentials) {
    this.authenticated = true;
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any[]>(this.apiurl + 'login', credentials, {headers});
  }
  signout() {
    this.authenticated = false;
    this.router.navigate(['/sessions/signin'], { queryParams:  filter, skipLocationChange: true, preserveFragment: true });
    // this.router.navigateByUrl("/sessions/signin");
  }

  profil() {
    this.router.navigateByUrl('/dashboard/profil');
  }

}
